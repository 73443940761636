<template>
  <header>
    <img
      alt="Las-dalias"
      class="logo cursor-pointer"
      src="@/assets/logo.png"
      width="200"
    />

    <nav>
      <a><RouterLink to="/">INICIO</RouterLink></a>
      <a><RouterLink to="/contacto">CONTACTO</RouterLink></a>
    </nav>
  </header>

  <RouterView />
  <FooterComponent></FooterComponent>
</template>

<script>
import { useRouter } from "vue-router";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  components: {
    FooterComponent
  },
  setup() {
    const router = useRouter();

    return {
      router
    };
  },
  computed: {

  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #6B705C;

  // Asegura que el header se mantenga en proporción para pantallas pequeñas y grandes.
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
}

.logo {
  width: 150px; // Ajusta proporcionalmente en pantallas pequeñas
  cursor: pointer;

  @media (min-width: 769px) {
    width: 200px; // Ajusta para pantallas más grandes
  }
}

nav {
  display: flex;
  gap: 1rem;
  
  // Ajusta el diseño de la navegación para pantallas pequeñas.
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  a {
    color: #AFAEAE;
    text-decoration: none;
    font-weight: bold;
    margin: 0;
    padding: 0 1em 0 1em;

    &:hover {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }

    &.router-link-exact-active {
      color: white;
    }

    @media (max-width: 768px) {
    
      font-size: small;
  }

    
  }
}

.main-content {
  min-height: calc(100vh - 200px); // Ajusta el espacio teniendo en cuenta el tamaño del header y footer.
}

body {
  background-color: #e3e5d3;
}
</style>