<template>
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Información del lote</h3>
            </div>
            <div class="modal-body">
                <div class="lote-info">
                    <h4 class="modal-title">Descripción</h4>
                    <span>
                        <strong>
                            Lote {{ loteSeleccionado.LOTE }} - Fracción {{ loteSeleccionado.FRACCION }}
                        </strong>
                    </span><br>
                    <span><strong>Superficie total:</strong> {{ loteSeleccionado.SUPERFICIE }} m²</span>
                </div>
                <div class="lote-image">
                    <img class="img-fluid" :src="`https://cdn.jsdelivr.net/gh/LasDaliasResidencialNorte/archivos@dalias/planos/${loteSeleccionado.id}.jpg`" alt="Plano del lote">
                </div>
                <div class="lote-pdf-link">
                    <a :href="`https://cdn.jsdelivr.net/gh/LasDaliasResidencialNorte/archivos@dalias/archivos/${loteSeleccionado.id}.pdf`" target="_blank" class="btn btn-primary">
                        Ver PDF del Lote
                    </a>
                </div>
                <div ref="pricingTable">
                    <h4 class="modal-title">Planes de pago</h4>
                    <span>
                        <strong>
                            Lote {{ loteSeleccionado.LOTE }} - Fracción {{ loteSeleccionado.FRACCION }}
                        </strong>
                    </span>
                    <div class="table-container">
                        <table class="pricing-table" >
                            <thead>
                                <tr>
                                    <th>Meses</th>
                                    <th>$M<sup>2</sup></th>
                                    <th>Precio</th>
                                    <th>Enganche</th>
                                    <th>Mensualidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(plan, index) in planes" :key="index">
                                    <td><strong>{{ plan.meses || 'Contado' }}</strong></td>
                                    <td>{{ formatCurrency(calculateM2(plan)) }}</td>
                                    <td>{{ formatCurrency(calculatePrice(plan)) }}</td>
                                    <td>{{ formatCurrency(calculateEnganche(plan)) }}</td>
                                    <td>{{ formatCurrency(calculateMensualidad(plan)) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="lote-pdf-link">
                    <button @click="generatePDF" class="btn btn-primary">Descargar precios</button>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="closeModal" class="btn btn-primary">Cerrar</button>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue';
import html2pdf from 'html2pdf.js';

export default {
    name: 'LoteInfoModal',
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        loteInfo: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            lotes: [
                { "id": 1, "FRACCION": 1, "LOTE": 1, "SUPERFICIE": 671.27, "Tipo": "ESQUINA" },
                { "id": 2, "FRACCION": 1, "LOTE": 2, "SUPERFICIE": 400, "Tipo": "STANDARD" },
                { "id": 3, "FRACCION": 1, "LOTE": 3, "SUPERFICIE": 400, "Tipo": "STANDARD" },
                { "id": 4, "FRACCION": 1, "LOTE": 4, "SUPERFICIE": 400, "Tipo": "STANDARD" },
                { "id": 5, "FRACCION": 1, "LOTE": 5, "SUPERFICIE": 400, "Tipo": "STANDARD" },
                { "id": 6, "FRACCION": 1, "LOTE": 6, "SUPERFICIE": 290.21, "Tipo": "STANDARD" },
                { "id": 7, "FRACCION": 1, "LOTE": 7, "SUPERFICIE": 659.15, "Tipo": "STANDARD" },
                { "id": 8, "FRACCION": 3, "LOTE": 1, "SUPERFICIE": 338.77, "Tipo": "ESQUINA" },
                { "id": 9, "FRACCION": 3, "LOTE": 2, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 10, "FRACCION": 3, "LOTE": 3, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 11, "FRACCION": 3, "LOTE": 4, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 12, "FRACCION": 3, "LOTE": 5, "SUPERFICIE": 219.19, "Tipo": "ESQUINA" },
                { "id": 13, "FRACCION": 3, "LOTE": 6, "SUPERFICIE": 197.52, "Tipo": "ESQUINA" },
                { "id": 14, "FRACCION": 3, "LOTE": 7, "SUPERFICIE": 240.5, "Tipo": "STANDARD" },
                { "id": 15, "FRACCION": 3, "LOTE": 8, "SUPERFICIE": 211.82, "Tipo": "STANDARD" },
                { "id": 16, "FRACCION": 3, "LOTE": 9, "SUPERFICIE": 222.2, "Tipo": "STANDARD" },
                { "id": 17, "FRACCION": 3, "LOTE": 10, "SUPERFICIE": 295.26, "Tipo": "ESQUINA" },
                { "id": 18, "FRACCION": 5, "LOTE": 1, "SUPERFICIE": 218.78, "Tipo": "ESQUINA" },
                { "id": 19, "FRACCION": 5, "LOTE": 2, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 20, "FRACCION": 5, "LOTE": 3, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 21, "FRACCION": 5, "LOTE": 4, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 22, "FRACCION": 5, "LOTE": 5, "SUPERFICIE": 206.99, "Tipo": "ESQUINA" },
                { "id": 23, "FRACCION": 5, "LOTE": 6, "SUPERFICIE": 196.95, "Tipo": "ESQUINA" },
                { "id": 24, "FRACCION": 5, "LOTE": 7, "SUPERFICIE": 207.35, "Tipo": "STANDARD" },
                { "id": 25, "FRACCION": 5, "LOTE": 8, "SUPERFICIE": 208.57, "Tipo": "STANDARD" },
                { "id": 26, "FRACCION": 5, "LOTE": 9, "SUPERFICIE": 209.79, "Tipo": "STANDARD" },
                { "id": 27, "FRACCION": 5, "LOTE": 10, "SUPERFICIE": 186.64, "Tipo": "ESQUINA" },
                { "id": 28, "FRACCION": 7, "LOTE": 1, "SUPERFICIE": 530.25, "Tipo": "ESQUINA" },
                { "id": 29, "FRACCION": 7, "LOTE": 2, "SUPERFICIE": 530.43, "Tipo": "STANDARD" },
                { "id": 30, "FRACCION": 7, "LOTE": 3, "SUPERFICIE": 920.94, "Tipo": "STANDARD" },
                { "id": 31, "FRACCION": 7, "LOTE": 4, "SUPERFICIE": 218.14, "Tipo": "STANDARD" },
                { "id": 32, "FRACCION": 7, "LOTE": 5, "SUPERFICIE": 238.11, "Tipo": "ESQUINA" },
                { "id": 33, "FRACCION": 7, "LOTE": 6, "SUPERFICIE": 222.33, "Tipo": "STANDARD" },
                { "id": 34, "FRACCION": 7, "LOTE": 7, "SUPERFICIE": 207.27, "Tipo": "ESQUINA" },
                { "id": 35, "FRACCION": 9, "LOTE": 1, "SUPERFICIE": 252.22, "Tipo": "ESQUINA" },
                { "id": 36, "FRACCION": 9, "LOTE": 2, "SUPERFICIE": 617.73, "Tipo": "ESQUINA" },
                { "id": 37, "FRACCION": 9, "LOTE": 3, "SUPERFICIE": 170.16, "Tipo": "ESQUINA" },
                { "id": 38, "FRACCION": 9, "LOTE": 4, "SUPERFICIE": 198.09, "Tipo": "STANDARD" },
                { "id": 39, "FRACCION": 9, "LOTE": 5, "SUPERFICIE": 198.36, "Tipo": "STANDARD" },
                { "id": 40, "FRACCION": 9, "LOTE": 6, "SUPERFICIE": 276.02, "Tipo": "ESQUINA" },
                { "id": 41, "FRACCION": 11, "LOTE": 1, "SUPERFICIE": 163.74, "Tipo": "ESQUINA" },
                { "id": 42, "FRACCION": 11, "LOTE": 2, "SUPERFICIE": 196.92, "Tipo": "STANDARD" },
                { "id": 43, "FRACCION": 11, "LOTE": 3, "SUPERFICIE": 196.92, "Tipo": "STANDARD" },
                { "id": 44, "FRACCION": 11, "LOTE": 4, "SUPERFICIE": 217.75, "Tipo": "ESQUINA" },
                { "id": 45, "FRACCION": 11, "LOTE": 5, "SUPERFICIE": 233.95, "Tipo": "ESQUINA" },
                { "id": 46, "FRACCION": 11, "LOTE": 6, "SUPERFICIE": 256.2, "Tipo": "STANDARD" },
                { "id": 47, "FRACCION": 11, "LOTE": 7, "SUPERFICIE": 293.92, "Tipo": "ESQUINA" },
                { "id": 48, "FRACCION": 13, "LOTE": 1, "SUPERFICIE": 117.77, "Tipo": "ESQUINA" },
                { "id": 49, "FRACCION": 13, "LOTE": 2, "SUPERFICIE": 186.76, "Tipo": "STANDARD" },
                { "id": 50, "FRACCION": 13, "LOTE": 3, "SUPERFICIE": 186.85, "Tipo": "STANDARD" },
                { "id": 51, "FRACCION": 13, "LOTE": 4, "SUPERFICIE": 243.75, "Tipo": "ESQUINA" },
                { "id": 52, "FRACCION": 13, "LOTE": 5, "SUPERFICIE": 101.75, "Tipo": "ESQUINA" },
                { "id": 53, "FRACCION": 13, "LOTE": 6, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 54, "FRACCION": 13, "LOTE": 7, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 55, "FRACCION": 13, "LOTE": 8, "SUPERFICIE": 200, "Tipo": "STANDARD" },
                { "id": 56, "FRACCION": 13, "LOTE": 9, "SUPERFICIE": 176.62, "Tipo": "ESQUINA" }
            ],
            planes: reactive([
                { meses: 0, m2: 1000 },
                { meses: 12, m2: 1100 },
                { meses: 24, m2: 1200 },
                { meses: 36, m2: 1300 },
            ]),
        };
    },
    computed: {
        loteSeleccionado() {
            return this.lotes.find(lote => lote.id === this.loteInfo);
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        calculateM2(plan) {
            return this.loteSeleccionado.Tipo === 'ESQUINA' ? plan.m2 + 100 : plan.m2;
        },
        calculatePrice(plan) {
            const m2 = this.calculateM2(plan);
            return this.loteSeleccionado.SUPERFICIE * m2;
        },
        calculateEnganche(plan) {
            if (plan.meses === 0) return 0;
            return this.calculatePrice(plan) * 0.3;
        },
        calculateMensualidad(plan) {
            if (plan.meses === 0) return 0;
            const price = this.calculatePrice(plan);
            const enganche = this.calculateEnganche(plan);
            return (price - enganche) / plan.meses;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        generatePDF() {
            const element = this.$refs.pricingTable; // Referencia a la tabla
            const options = {
                margin: 1,
                filename: `Planes de Pago Lote ${this.loteSeleccionado.LOTE} - Fracción ${this.loteSeleccionado.FRACCION} .pdf`,
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2},
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            // Genera el PDF
            html2pdf().from(element).set(options).save();
            
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal-content {
    padding: 0;
    border-radius: 10px;
    max-width: 625px;
    width: 100%;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0.5em;
}

.modal-header {
    background-color: #6b705c;
    color: white;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
}

.modal-body {
    background-color: #c5cbb7;
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
}

.modal-body img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

.table-container {
    padding: 0em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 4px;
    width: 100%;
    overflow-x: auto;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    color: #6b705c;
    min-width: 400px;
}

.pricing-table th,
.pricing-table td {
    padding: 0.5em 1em;
    border: 1px solid #6b705c;
}

.pricing-table th {
    background-color: #6b705c;
    color: #e3e5d3;
    font-weight: bold;
}

.pricing-table td {
    background-color: #e3e5d3;
}

.modal-footer {
    padding: 10px 20px;
    background-color: #c5cbb7;
    text-align: right;

}

.btn-primary {
    background-color: #6b705c;
    border-color: #6b705c;
    color: #e3e5d3;
}

.btn-primary:hover {
    background-color: #556052;
    border-color: #556052;
    color: #e3e5d3;
}

.lote-pdf-link {
    padding: 10px 0px 10px 0px;
    align-items: flex-end;
    text-align: right;
}
</style>