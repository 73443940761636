import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import VueFeather from 'vue-feather';

const app = createApp(App);

app.use(router);
app.component(VueFeather.name, VueFeather);

app.mount('#app');
