<template>
    <div class="contact-page">
        <!-- Sección de contacto -->
        <section class="contact-info py-4">
            <div class="container">
                <h1 class="section-title text-center">CONTÁCTANOS</h1>
                <p class="section-subtitle text-center">Queremos ayudarte a encontrar tu lugar perfecto</p>
                <div class="row align-items-center">
                    <div class="col-md-6 text-center">
                        <img src="https://cdn.jsdelivr.net/gh/LasDaliasResidencialNorte/archivos@dalias/planos/contact-img.dc6ffb62.jpg" alt="Contact Image" class="img-fluid contac-img" />
                    </div>
                    <div class="col-md-6 contact-details">
                        <div class="d-flex align-items-center mb-2">
                            <img src="../assets/whatsapp1.png" alt="Contact Image"
                                class="icono d-flex whatsapp align-items-center mb-1" />

                            <div class="mb-2">
                                <h4 class="icon-title">Teléfono</h4>
                                <a href="https://wa.me/529993221733">
                                    <p class="mb-0">9993221733</p>
                                </a>
                            </div>

                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <vue-feather type="mail" class="icono d-flex align-items-center mb-1" stroke="#E3E5D3"
                                fill="#6B705C" size="38"></vue-feather>
                            <div class="mb-2">
                                <h4 class="icon-title">Email</h4>
                                <a
                                    href="mailto:lasdaliasresidencialnorte@gmail.com">lasdaliasresidencialnorte@gmail.com</a>
                            </div>

                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <vue-feather type="map-pin" class="icono d-flex align-items-center mb-1" stroke="#E3E5D3"
                                fill="#6B705C" size="36"></vue-feather>
                            <div class="mb-2">
                                <h4 class="icon-title">Ubicación</h4>
                                <p class="mb-0 ">Entre el fracc. Benito Juárez y <br>la carretera Tizimín - Cheé
                                    Keken</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Sección de video principal -->
        <section class="video-section py-5">
            <div class="container text-center">
                <img src="../assets/dalia.png" alt="Contact Image" class="img-fluid dalia-img" />
                <h3 class="section-subtitle mb-4">Las Dalias Residencial Norte</h3>
                <div class="video-fluid">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/kWb7YYaWJNU?si=mK2uvhEylRW85Rdy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

            </div>
        </section>

        <!-- Sección de ubicación -->
        <section class="location-section py-5">
            <div class="container text-center">
                <h3 class="section-subtitle mb-4">
                    <vue-feather type="map-pin" class="icono" stroke="#E3E5D3" fill="#6B705C" size="36"></vue-feather>
                    Ubícanos
                </h3>
                <div class="map-responsive">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1198.6621790023437!2d-88.140128839554!3d21.160120974741474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f522b002bc835b9%3A0x995ad659713e1f67!2sLas%20Dalias%20Residencial%20Norte!5e1!3m2!1ses-419!2smx!4v1725419366063!5m2!1ses-419!2smx"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div class="lote-pdf-link">
                    <a :href="`https://cdn.jsdelivr.net/gh/LasDaliasResidencialNorte/archivos@dalias/archivos/BROCHURE.pdf`" target="_blank" class="btn btn-primary">
                        Conoce más
                    </a>
                </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "ContactPage",
};
</script>

<style scoped lang="scss">
.btn-primary {
    background-color: #6b705c;
    border-color: #6b705c;
    color: #e3e5d3;
}

.btn-primary:hover {
    background-color: #556052;
    border-color: #556052;
    color: #e3e5d3;
}

.lote-pdf-link {
    margin-top: 15px;
    align-items: center;
    text-align: center;
}
.contact-page {
    background-color: #E3E5D3;
}

.contact-info {
    background-color: #E3E5D3;
    color: #6B705C;

    .section-title,
    .section-subtitle {
        color: black;
    }

    .contac-img {
        width: 100%;
        max-width: 700px;
        height: auto;
    }

    .contact-details {
        text-align: left;
    }

    .icono {
        margin-right: 10px;

    }

    .whatsapp {
        width: 32px;
        height: auto;
    }

    .icon-title {
        margin: 0 10px 0 0;
    }

    p {
        font-size: large;
        color: black;
    }

    a {
        font-size: large;
        text-decoration: none;
        color: black;
    }

}


.location-section {
    background-color: #E3E5D3;
    width: 100%;

    .section-subtitle {
        font-size: 1.5rem;
        color: #6B705C;
    }

    .map-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;

        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }

    .icono {
        margin-right: 5px;
        margin-bottom: -10px;
    }
}



.video-section {
    background-color: #DADDC6;
    width: 100%;

    .section-subtitle {
        font-size: 1.5rem;
        color: #6B705C;
    }

    .dalia-img {
        min-width: 20px;
        max-width: 60px;
    }


    .video-fluid {
        width: 100%;
        height: auto;
        max-height: 500px;
        border-radius: 5px;
        object-fit: cover;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
}

@media (max-width: 700px) {
    .contact-info {
        padding: 10px;
    }

    .contact-details {
        text-align: center; // Cambiar a centralizado para pantallas pequeñas
    }

    .contact-details .d-flex {
        flex-direction: column; // Cambiar disposición a columna para pantallas pequeñas
        align-items: center;
    }

    .icono {
        size: 30px; // Asegurar que todos los iconos tengan el mismo tamaño
        margin-bottom: 0; // Agregar margen para separar el icono del texto

    }

    .icon-title {
        margin: 0; // Quitar márgenes adicionales
        font-size: 1.2rem; // Ajustar tamaño de fuente
        text-align: center; // Centrar el texto
    }

    p,
    a {
        font-size: 1rem; // Ajustar tamaño de fuente
        text-align: center; // Centrar el texto
    }

    .contac-img {
        margin-bottom: 20px; // Espacio entre la imagen y los detalles de contacto

    }
}
</style>