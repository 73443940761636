<template>
  <footer>
    <div class="container-fluid">
      <div class="footer-content">
        <div class="social-media">
          <p class="contact-title"><strong>Redes sociales</strong></p>
          <a href="https://wa.me/529993221733"><img src="../assets/whatsapp2.png" alt="Contact Image" class="whatsapp"/></a>
          <a href="https://www.facebook.com/profile.php?id=61560243892238&mibextid=ZbWKwL"><vue-feather type="facebook" stroke="white"></vue-feather></a>
          <a href="https://www.instagram.com/lasdaliasresidencialnorte?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><vue-feather type="instagram" stroke="white"></vue-feather></a>
        </div>
        <div class="divider"></div>
        <div class="contact-info">
          <p class="contact-title"><strong>Información de contacto</strong></p>
          <p class="info">
            <span><vue-feather type="map-pin" stroke="white" class="icon"></vue-feather> Tizimín, Yucatán, México</span>
            <span><vue-feather type="mail" stroke="white" class="icon"></vue-feather><a href="mailto:lasdaliasresidencialnorte@gmail.com"> lasdaliasresidencialnorte@gmail.com</a></span>
            <span><vue-feather type="phone" stroke="white" class="icon"></vue-feather>  9993221733</span>
          </p>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
import feather from 'feather-icons';

const icons = Object.keys(feather.icons);

export default {
  name: 'FooterComponent',
  icons,
};
</script>

<style scoped>
  footer {
    background-color: #6B705C;
    color: white;
    padding: 20px;
    text-align: center;
    display: flex; /* Usa flexbox para centrar el contenido */
    justify-content: center; /* Centra el contenido horizontalmente */
    
  }

  .whatsapp{
    margin-top: -1em;
    width: 24px;
    height: auto;
  }
@media (min-width: 700px) {
  
  .footer-content {
    display:inline-flex;
    align-items: center;
    align-content: center;
    margin-right: -10em;

  }

  .footer-content div {
    margin: 10px;
    
  }

  .footer-content .social-media {
    margin-top: -4.5em;
  }

  .footer-content .divider {
    height: 9em;
    width: 2px;
    background-color: white;
    margin: 0 10px;
  }

  .footer-content a {
    color: white;
    text-decoration: none;
    margin: 0
  }

  .footer-content a:hover {
    text-decoration: none;
  }

  .contact-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-info .info span{
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}


.icon{
  padding-right: 5px;
}


  

}
@media (max-width: 699px) {
  .footer-content {
    display: block;
    align-items: center;
  }

  .contact-title {
    margin-bottom: 0.2em;
    margin-top: 0.2em;
  }

  .footer-content a {
    color: white;
    text-decoration: none;
    margin: 0;
  }

  .footer-content a:hover {
    text-decoration: none;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-info .info{
    display: contents;
    align-items: center;
    
  }

  /* Ocultar iconos en pantallas menores a 700px */
  .icon {
    display: none;
  }
}
</style>
